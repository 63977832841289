<template>
  <div>
    <vs-sidebar
        position-right
        v-model="active"
        parent="body" default-index="55555551"
        class="items-no-padding">
        <!-- 
        hidden-background -->

        <div v-if="isLoadingAffair || isUpdatingAffair || isDeletingAffair" class="h-100">
            <div class="text-center flex-center h-100">
                <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Chargement...</span>
                </div>
                <br />
                Chargement des détails de l'affaire...
            </div>
        </div> 

        <div v-else class="h-full">
            

            <div class="sidebar-header py-1 px-2">
                <div>
                    <h3>{{affair.name}}</h3>
                    <small v-show="affair.matricule">Matricule : {{affair.code}}</small>
                </div>
                <feather-icon icon="XIcon" size="24" @click.stop="active =!active" class="close-icon cursor-pointer"></feather-icon>
            </div>

            <vs-divider class="m-0" />

            <div class="sidebar-action py-05 px-2">
              <div class="d-flex align-items-center">
                  <b-button
                    v-if="archived"
                    variant="warning"
                    class="btn-icon"
                    @click="restoreAffairLocal(affair.id, affair.name)"
                  >
                      <feather-icon icon="ArrowDownIcon" /> Restaurer
                  </b-button>
                  <b-button
                    v-else
                    class="btn-icon"
                    variant="primary"
                    @click="editAffair(affair.id)"
                    :disabled="archived"
                  >
                      <feather-icon icon="EditIcon" /> Modifier
                  </b-button>
              </div>
              <b-button-group>
                  <b-button
                  v-if="archived"
                    variant="outline-danger"
                    class="btn-icon"
                    @click="deleteAffairLocal(affair.id, affair.name)"
                  >
                      <feather-icon icon="TrashIcon" />  Supprimer définitivement
                  </b-button>
                  <b-button
                    v-else
                    variant="outline-danger"
                    class="btn-icon"
                    @click="archiveAffairLocal(affair.id, affair.name)"
                  >
                      <feather-icon icon="ArchiveIcon" /> 
                  </b-button>
              </b-button-group>
            </div>

            <vs-divider class="m-0" />

            <div class="sidebar-content">
                <div v-show="affair.startDate || affair.endDate || affair.description">
                  <p class="paraphbox bg-white" v-if="affair.description">
                    {{affair.description}}
                  </p>
                  <ul class="listTab" v-if="affair.startDate || affair.endDate">
                      <li>
                          <span>Date de début</span>
                          <span>{{affair.startDate ? new Date(affair.startDate).toLocaleDateString() : ''}}</span>
                      </li>
                      <li>
                          <span>Date de fin</span>
                          <span>{{affair.endDate ? new Date(affair.endDate).toLocaleDateString() : ''}}</span>
                      </li>
                  </ul>
                  <vs-divider /> 
                </div>
                <div v-if="affair.company">
                    <h4 class="mb-1 text-primary d-flex justify-content-between">
                      <span>
                        <feather-icon icon="ChevronRightIcon" /> Client lié
                      </span>
                      <span>
                        {{affair.company.name}}
                        <feather-icon icon="ExternalLinkIcon" class="cursor-pointer" @click="editCompany(affair.company.id)" />
                      </span>
                    </h4>
                    <ul class="listTab">
                        <li v-if="affair.company.email">
                            <span>Email</span>
                            <span>{{affair.company.email}}</span>
                        </li>
                        <li v-if="affair.company.phoneNumber">
                            <span>Téléphone</span>
                            <span>{{affair.company.phoneNumber}}</span>
                        </li>
                        <li v-if="affair.company.secondaryPhoneNumber">
                            <span>Tél secondaire</span>
                            <span>{{affair.company.secondaryPhoneNumber}}</span>
                        </li>
                    </ul>
                    <vs-divider /> 
                </div>
                <div v-show="affair.address || affair.zipCode || affair.city || affair.country">
                    <h4 class="mb-1 text-primary"><feather-icon icon="MapPinIcon" /> Adresse</h4>
                    <p class="paraphbox">
                        <span v-show="affair.address">{{affair.address}}<br></span>
                        <span v-show="affair.addressComplement">{{affair.addressComplement}}<br></span>
                        <span v-show="affair.zipCode || affair.city ">{{affair.zipCode}} {{affair.city}}<br></span>
                        <span v-show="affair.country ">{{affair.country}}</span>
                    </p>
                    <vs-divider /> 
                </div>
                <div v-show="affair.job || affair.contract || affair.matricule || affair.socialSecurityNumber">
                    <h4 class="mb-1 text-primary"><feather-icon icon="ClipboardIcon" /> Juridique</h4>
                    <ul class="listTab">
                        <li v-if="affair.job">
                            <span>Métier</span>
                            <span>{{affair.job.label}}</span>
                        </li>
                        <li v-if="affair.contract">
                            <span>Contrat</span>
                            <span>{{affair.contract.label}}</span>
                        </li>
                        <li v-show="affair.matricule">
                            <span>Matricule</span>
                            <span>{{affair.matricule}}</span>
                        </li>
                        <li v-show="affair.socialSecurityNumber">
                            <span>N° Sécurité Sociale</span>
                            <span>{{affair.socialSecurityNumber}}</span>
                        </li>
                    </ul>
                    <vs-divider /> 
                </div>
            </div>
        </div>
    </vs-sidebar>
  </div>
</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      archived:false,
      affair:{}
    }
  },
  computed: {
    ...mapGetters([
      "isUpdatingAffair",
      "affairsList",
      "isLoadingAffair",
      "isDeletingAffair",
      "isOpenSidebarAffair",
    ]),
    active: {
      get ()    { return this.isOpenSidebarAffair },
      set (val) { this.$store.commit('SET_IS_OPEN_SIDEBAR_AFFAIR', val) }
    },
  },
  methods: {
    ...mapActions(["updateAffair", "deleteAffair", "isArchivingAffair", "archiveAffair", "fetchAffair", "fetchAffairArchived","restoreAffair"]),

    getAffairArchivedDetails(id, archived) {
      this.archived=archived
      if (id != '') {
        this.fetchAffairArchived(id).then(res => {
          this.affair = res
        })
      } else {
        this.affair={}
      }
    },
    getAffairDetails(id) {
      this.archived=false
      if (id != '') {
        this.fetchAffair(id).then(res => {
          this.affair = res
        })
      } else {
        this.affair={}
      }
    },

    editAffair(id){
        this.$store.commit('SET_IS_OPEN_SIDEBAR_AFFAIR', false)
        this.$router.push({ name: 'edit-affair', params: { id: id, title: "Edit : "+ this.affair.name, tips:"Editer le collaborateur : "+this.affair.name, routeOrigine:'affairs' } })
    },
    editCompany(id){
        this.$store.commit('SET_IS_OPEN_SIDEBAR_AFFAIR', false) 
        this.$router.push({ name: 'edit-company', params: { id: id, title: "Edit : "+ (this.affair.company.name ? this.affair.company.name : this.affair.company.firstName + ' ' + this.affair.company.lastName), tips:"Editer l\'entreprise : "+(this.affair.company.name ? this.affair.company.name : this.affair.company.firstName + ' ' + this.affair.company.lastName), routeOrigine:'affairs' } })
    },
    
    archiveAffairLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer cette affaire en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver l\'affaire "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveAffair(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_AFFAIR', false)
          }
        })
    },
    restoreAffairLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.', {
          title:
            'Êtes-vous sûr de vouloir restaurer l\'affaire "' + label + '" ?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Restaurer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.restoreAffair(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_AFFAIR', false)
          }
        })
    },
    deleteAffairLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir supprimer l\'affaire "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteAffair(id)
            this.$store.commit('SET_IS_OPEN_SIDEBAR_AFFAIR', false)
          }
        })
    },
  },
  components: {
    VuePerfectScrollbar
  }
}
</script>

