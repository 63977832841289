<template>
  <div>
    <b-row class="m-0">
      <b-col
        class="content-header"
        cols="12"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }} <span v-show="archive" class="text-danger">&nbsp;(archive)</span>
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input
            id="searchAffair"
            v-model="searchAffair"
            placeholder="Rechercher une affaire"
            v-on:input="onFilterTextBoxChanged()"
            class="w-full"
          />
        </div>
        <div class="content-header-actions">
          <b-button
            variant="primary"
            @click="newAffair('')"
            class="px-1 mr-1"
          > 
            <feather-icon icon="PlusIcon" />
            Nouveau 
          </b-button>
          <vs-dropdown class="cursor-pointer">
            <div>
              <b-button
                variant="outline-primary"
                class="px-1"
              >
                  <feather-icon icon="SettingsIcon" />
                  <!-- Paramètres -->
              </b-button>
            </div>
            <vs-dropdown-menu class="w-full">
                <vs-dropdown-item class="w-full border-bottom-grey-light" @click="archive=!archive">{{ !archive ? 'Voir les affaires archivées' : 'Voir les affaires actives' }}</vs-dropdown-item>
                <vs-dropdown-item class="w-full border-bottom-grey-light" @click="togglePanelColumn">{{this.gridOptions.sideBar == null? 'Gérer les colonnes' : 'Masquer la gestion des colonnes' }}</vs-dropdown-item>
                <vs-dropdown-item class="w-full" @click="exportDataCSV">Exporter en CSV</vs-dropdown-item>
                <vs-dropdown-item class="w-full" @click="exportDataXLS">Exporter en XLS</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div class="text-center flex-center" v-if="isLoadingAffairsList || isLoadingAffairsListArchived">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        {{archive ? 'Chargement des archives en cours...' : 'Chargement en cours de la liste des affaires...'}}
      </div>
      <ag-grid-vue v-else style="width: 100%; height: 100%;"
          class="ag-theme-material"
          :class="archive ? 'ag-archived' : ''"
          :rowData="gridData"
          :gridOptions="gridOptions"
          :key="archive+filter"
          @grid-ready="onGridReady"
          @rowClicked="onRowClicked">
      </ag-grid-vue>
    </div>
    <sidebar-affair ref="sidebarAffair"/>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
import { agGridLocaleFR } from "@/data/agGridLocaleFR"

import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'

import CellRendererColors from '@/components/directory/affair/CellRendererColors'

import SidebarAffair from '@/components/directory/affair/SidebarAffair'


export default {
  components: {
    BTable,
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    VBTooltip,
    AgGridVue,
    CellRendererColors,
    SidebarAffair
  },
  directives: {
    Ripple
  },
  data () {
    return {
      archive:false,
      filter: false,
      sidebar:false,
      activeSidebarAffair:false,
      gridOptions:{
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination:true,
        paginationPageSize:20,
      },
      gridApi: null,
      columnApi: null,
      searchAffair: ''
    }
  },
  computed: {
    ...mapGetters([
      'affairsList',
      'affairsListArchived',
      'isLoadingAffairsList',
      'isLoadingAffairsListArchived',
      'isOpenSidebarAffair',
    ]),
    gridData () {
      if(this.archive){
        return this.$store.getters.affairsListArchived
      }else{
        return this.$store.getters.affairsList
      }
    }
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      { field: 'color', headerName: 'Couleur', resizable:true,  sortable: true, maxWidth: 120, cellRenderer: 'CellRendererColors' },
      { field: 'code', headerName: 'Code', sortable: true, resizable:true, maxWidth: 100,},
      { field: 'name', headerName: 'Nom', sortable: true, resizable:true,  minWidth: 260},
      { field: 'description', headerName: 'Description',  resizable:true,  sortable: true, minWidth: 260},
      { field: 'company.name', headerName: 'Client lié', sortable: true, resizable:true,  minWidth: 160},
      { field: 'startDate', headerName: 'Date de début', sortable: true, maxWidth: 180, headerClass: 'ag-header-cell-center', cellStyle: {textAlign: 'center'}, cellRenderer: params => {
          return params.value ? new Date(params.value).toLocaleDateString() : ''
        } 
      },
      { field: 'endDate', headerName: 'Date de fin', sortable: true, maxWidth: 180,headerClass: 'ag-header-cell-center', cellStyle: {textAlign: 'center'}, cellRenderer: params => {
          return params.value ? new Date(params.value).toLocaleDateString() : ''
        }
      },
      { field: 'address', headerName: 'Adresse', resizable:true, initialHide:true, sortable: true, width: 'fit-content' },
      { field: 'addressComplement', headerName: 'Complément d\'adresse', wrapText:true, resizable:true, initialHide:true, sortable: true },
      { field: 'zipCode', headerName: 'Code postal', resizable:true, initialHide:true, sortable: true },
      { field: 'city', headerName: 'Ville', resizable:true, initialHide:true, sortable: true },
      { field: 'country', headerName: 'Pays', resizable:true, initialHide:true, sortable: true },
    ];
  },
  methods: {
    ...mapActions([ 
      'fetchAffairsList',
      'fetchAffairsListArchived',
      'deleteAffair'
    ]),

    // Gestion d'AgGrid
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    onRowClicked(event){
      if(this.archive){
        this.$refs['sidebarAffair'].getAffairArchivedDetails(event.data.id, 1)
      }else{
        this.$refs['sidebarAffair'].getAffairDetails(event.data.id)
      }
      this.$store.commit('SET_IS_OPEN_SIDEBAR_AFFAIR', true)
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
        this.searchAffair
      );
    },
    togglePanelColumn(){
      if(!this.gridOptions.sideBar || this.gridOptions.sideBar== null ){
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Colonnes',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        }
        this.filter=true;
      }else{
        this.gridOptions.sideBar= null
        this.filter=false;
      }
    },
    exportDataXLS(){
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV(){
      this.gridApi.exportDataAsCsv();
    },

    // Sidebar affair
    newAffair (id) { 
      this.$router.push({ path: '/new-affair' })
    },
  }
}
</script>
